import React from 'react'
import '../css/staff.css';
import { Helmet } from 'react-helmet';
import coming_soon from '../images/coming_soon.jpg'
import lee from '../images/staff/LeAndrena-Hadlock.jpg'
import joy from '../images/staff/Joy-Quinney.jpg'
import karena from '../images/staff/Karena-Soderborg.jpg'
import wendy from '../images/staff/Wendy-Francis.jpg'
import nathan from '../images/staff/Nathan-Hadlock.jpg'
import shanna from '../images/staff/Shanna-Davies.jpg'
import adelaide from '../images/staff/Adelaide-Soderborg.jpg'
import sadie from '../images/staff/Sadie-Baugh.jpg'
import april from '../images/staff/April-Hamon.jpg'
import destanee from '../images/staff/Destanee-Webb.jpg'
import briley from '../images/staff/Briley-Grange.jpg'
import jaclyn from '../images/staff/Jaclyn-Moore.jpg'
import vivian from '../images/staff/Vivian-Davis.jpg'
{ /* import kayla from '../images/staff/Kayla-Baugh.jpg' */}
{ /* import zaiah from '../images/staff/Zaiah-Johnson.jpg' */}

export default function Staff() {
    return (
        <div className="content">
            <Helmet>
                <title>In Motion Dance | Staff</title>
            </Helmet>
            <h1 className="staff-topText"> Meet our team! </h1>

            {/* LeAndrena Hadlock */}
            <div class="container">
                <div class="row" id="staff-firstRow">
                <div class="staff-column-33">
                        <img src={lee} alt='LeAndrena_Hadlock' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>LeAndrena Hadlock</h2>
                        <h3>Owner/Instructor, Director of En Pointe Ballet Company  – Misc Classes:</h3>
                        <p>Lee started dance training at 19. Within a year, she was a professional
                             dancer in LA. She trained in hip-hop, ballet/pointe, tap, clogging, 
                             jazz, tumbling and modern. She was a College cheerleader in California
                              and has taught for many studios, the LA &#38; Alpine School District, and
                               choreographed for school plays &#38; community organizations. She also 
                               trained at BYU (dance minor) where she was on Dancensemble (modern 
                               company) and earned a BS in Human Development. She also started a Hip-Hop
                                Crew at BYU, (Y-Girls).  She trained hip hop with Shane Sparks (SYTYCD 
                                Choreographer/America’s Best Dance Crew), Rick Robinson (Center Stage, Vibe)
                                 and Jaymz Tuialeva (SYTYCD/High School Musical/Dancing with the Stars). 
                                 She has danced in ballets such as: Swan Lake, Sleeping Beauty, Nutcracker,
                                  Alice in Wonderland and Giselle. She coached Rockwell High Dance Team and 
                                  has taught for over 30 years. She taught Zumba for 8 years and loves spending 
                                  time with her 5 children (ages 14 -23), who have all taught and danced at In Motion.</p>
                    </div>
                </div>
            </div>

            {/* Joy Quinney */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={joy} alt='Joy_Quinney' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Joy Quinney</h2>
                        <h3>Office Manager:</h3>
                        <p>Joy was born in Idaho and is a mother of 5 and grandma of 1 precious grandchild. Joy taught for 4 years at In Motion,
                             coached at Olympus Gymnastics, worked in the education 
                             field and also the Radiology field. She enjoyed Cheer and Dance in high school and was also on the BYU-Idaho
                              Competition clogging team. She graduated from Idaho State with a Bachelor’s Degree in X-ray.  
                              Joy excels at keeping our studio organized and families well informed.</p>
                    </div>
                </div>
            </div>

            {/* Karena Soderborg */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={karena} alt='Karena_Soderborg' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Karena Soderborg</h2>
                        <h3>Tap 2 &#38; 3, Adult Tap, BTT Combo:</h3>
                        <p>Karena was born in Napa, California where she enrolled in tap and ballet classes at the age of three. 
                             Since then Karena has taken lessons in many other forms of dance and enjoys them all, but considers 
                             tap and clogging to be her favorites.  As an adult, Karena has danced in productions in both California 
                             and Utah.  Along with her love for dancing, she has a love for teaching.  She received her B.S. in 
                             Elementary Education from Brigham Young University and currently teaches in the Alpine School District.
                               She enjoys teaching at In Motion Dance Studio in her spare time.</p>
                    </div>
                </div>
            </div>

            {/* Wendy Francis */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={wendy} alt='Wendy_Francis' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Wendy Francis</h2>
                        <h3>Pointe, Elite Dance Company:</h3>
                        <p>Wendy has been dancing since the age of 4. She has had extensive training in many forms of dance, including 
                            Jazz and Modern, but by the age of 11, she had developed a strong love of ballet. She trained at the Cachet 
                            School of Ballet and was a member of the Cachet Ballet Company, under the direction of JoAnn Loveland.  
                            Wendy has performed in several productions, including “The Little Match Girl”, “A Night with Gershwin”, 
                            “The Nutcracker”, “Paquita”, and “Swan Lake”, to name a few. She has been a ballet instructor for many years 
                            and loves teaching the art of dance. When she’s not dancing, Wendy enjoys spending time with her husband, 
                            Russ, and their 4 children. </p>
                    </div>
                </div>
            </div>

            {/* Nathan Hadlock */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={nathan} alt='Nathan_Hadlock' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Nathan Hadlock</h2>
                        <h3>Breakdance:</h3>
                        <p>This is Nathan’s fifth year of teaching, after being the breakdance class assistant for 1 year. He has been break-dancing 
                            since he was 5 and has also trained in hip hop, modern, ballet and ballroom. He was on the Westlake Ballroom Freshman Team 
                            for several years. He was awarded the “Best Performer” at Westlake’s End of Year Concert. He won 1st place several times 
                            at the Shakespeare competition as a team, but also 1st place as a member of a trio. He was a member of Frontier Dance 
                            Company and the award-winning Westlake Dance Company. His hobbies include piano, steel drum, painting, reading, &#38; gaming 
                            with friends.</p>
                    </div>
                </div>
            </div>

            {/* Destanee Webb */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={destanee} alt='Destanee_Webb' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Destanee Webb</h2>
                        <h3>Eclipse & Emerge Company / Other Misc Classes:</h3>
                        <p>Destanee started dancing at the age of 6 at In Motion training in Contemporary, Hip Hop, Jazz and Acro.  She joined Frontier Middle School 
                            Dance Company (2018) as well as Cedar Valley High School Dance Company (2019-2022). She is starting her third year of teaching at 
                            In Motion where she is busy teaching many classes and many styles of dance as well as tumbling.  Her favorite dance styles are 
                            Hip Hop and Contemporary; she loves pasta, reading and sharing her passion for dance through teaching at In Motion.</p>
                    </div>
                </div>
            </div>

            {/* Adelaide Soderborg */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={adelaide} alt='Adelaide_Soderborg' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Adelaide Soderborg</h2>
                        <h3>INT BTT / Tumbling 2:</h3>
                        <p>Adelaide started dancing at the age of 18 months old and has spent a lot of time at In Motion with her mom (Karena) who has been teaching 
                            at In Motion for 17 years.  Adelaide attends Cedar Valley High School and was on the dance team at Frontier Middle School.  This is 
                            Adelaide's second year teaching BTT and Tumbling, her favorite dance style is Tap. She has been in 4 musicals and loves to sing and perform.  
                            She also enjoys reading and hiking and a few of her favorite foods are donuts,  Watermelon Sourpatch kids, Mike n' Ikes and Sprite.</p>
                    </div>
                </div>
            </div>

            {/* Sadie Baugh */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={sadie} alt='Sadie_Baugh' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Sadie Baugh</h2>
                        <h3>Ballet 5 &#38; 6 / Jazz 3 &#38; 4:</h3>
                        <p>Sadie is in her second year of teaching at In Motion.  She has been dancing since she was 5, learning multiple dance styles along the way.  
                            This has helped her become adept at teaching both Ballet and Jazz at In Motion.  She is a member of the Cedar Valley Dance Company as well as our In Motion Elite Dance 
                            Company, she also dances Ballet Pointe. When she is not dancing Sadie enjoys spending time with family and friends, drinking 
                            Dr. Pepper and eating Goldfish. </p>
                    </div>
                </div>
            </div>

            {/* Angel Rigby */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={coming_soon} alt='Angel_Rigby' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Angel Rigby</h2>
                        <h3>Ballet 2 / Jazz 2:</h3>
                        <p>Angel's BIO to come.</p>
                    </div>
                </div>
            </div>

            {/* Briley Grange */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={briley} alt='Briley_Grange' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Briley Grange</h2>
                        <h3>Jazz / Hip Hop 1:</h3>
                        <p>Briley has been dancing for 12 years and is currently a Junior at Cedar Valley High School.  She was a dance instructor 
                            assistant at a previous dance studio before joining our dance studio last year as an Elite Dance Company member. 
                            Briley's favorite dance styles are Jazz and Contemporary, she loves art and especially loves painting.  In her spare time 
                            she enjoys helping her mom cook and she LOVES cheese.  An interesting fact about Briley is that she has 9 pets!</p>
                    </div>
                </div>
            </div>
            
            {/* Jaclyn Moore */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={jaclyn} alt='Jaclyn_Moore' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Jaclyn Moore</h2>
                        <h3>Ballet / Jazz 1:</h3>
                        <p>Jaclyn is a Junior at Cedar Valley High School. Her passion is dancing and she is very dedicated to learning and improving.  
                            She has been dancing since the age of 7 and as an 8th grader she choreographed and taught a routine for the school dance company.  
                            Jaclyn has danced at her school and been a member of our In Motion Dance Company teams for 5 years with this being her second year on our 
                            Advanced Elite Company team. She also puts in a lot of time as one of our Ballet Pointe Dancers.   
                            Along with dancing, she enjoys baking and painting. </p>
                    </div>
                </div>
            </div>

            {/* Vivian Davis */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={vivian} alt='Vivian_Davis' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Vivian Davis</h2>
                        <h3>Ballet 3/4:</h3>
                        <p>Vivian has been dancing at In Motion since the age of 3 where she has been on one of our Dance Company teams for 4 years and this is 
                            her second year on our Advanced Elite Dance Company. She is a great student and loves school, she especially enjoys taking medical 
                            classes as a Sophomore at Cedar Valley High School.  Vivian's passion is dance and she was able to participate at the Stadium of Fire 
                            this summer as a dancer.  Along with dancing, Vivian loves spending time with her family and friends and is very excited to be 
                            teaching at In Motion. </p>
                    </div>
                </div>
            </div>

            
                
            {/* Shanna Davies */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={shanna} alt='Shanna_Davies' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Shanna Davies</h2>
                        <h3>Zumba Fitness:</h3>
                        <p>began her dance training at age 5 in Long Beach, CA and continued to dance from ages 9 – 15 when she lived in Sydney, Australia with her family. 
                             She had many opportunities to perform at the Sydney Opera house, winning 1st place in a dance competition.  After moving to Utah she continued 
                             her study in Ballet, in roles including Clara in the Nutcracker, Snow Queen, Arabian dancer, Spanish dancer, and Waltz of the Flowers soloist. 
                             She attended BYU’s summer ballet workshop, and was on Alta High School’s Dance company for 3 years.  She was accepted into the intense ballet 
                             program at the University of Utah as a ballet major and studied there for 1 year.  After taking a break, she was introduced to Zumba and was 
                             instantly addicted (where she now gets her dance fix).  She enjoys sharing her love of dance &#38; fitness and keeps busy as a wife &#38; mother of 6.</p>
                    </div>
                </div>
            </div>
             
            {/* April Hamon */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={april} alt='April Hamon' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>April Hamon</h2>
                        <h3>Dance Fitness:</h3>
                        <p>April's BIO to come.</p>
                    </div>
                </div>
            </div>
            
            {/* Addie Oliverson */}
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={coming_soon} alt='Addie_Oliverson' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Addie Oliverson</h2>
                        <h3>Yoga Fitness:</h3>
                        <p>Addie's BIO to come.</p>
                    </div>
                </div>
            </div>
            
            {/* Isaac Lambert */}
             {/*<div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={isaac} alt='Isaac Lambert' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Isaac Lambert</h2>
                        <h3>Breakdance</h3>
                        <p>Isaac has assisted in our break dance classes for a couple of years and is currently in his first year of teaching.
                              Isaac also takes dance at our school and is a Junior in high school.  He loves orange juice, pizza and Jack in the Box.</p>
                    </div>
                </div>
            </div> */}           
            
            
            {/* McKenna Law */}
             {/*<div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={mckenna} alt='McKenna_Law' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>McKenna Law</h2>
                        <h3>Ballet 2:</h3>
                        <p>McKenna started dancing when she was 4. When she was young she wanted so badly to be a ballerina, so she has worked through the years to become 
                            a pointe dancer. Along the way she has also found a love for jazz, and lyrical dance and joined the IMDS Elite Company. She has loved working
                             with her amazing team the past 4 years! She was a member of the FMS Mustang movers in middle school and is now a member of Cedar Valley dance
                              company!</p>
                    </div>
                </div>
            </div> */}
            

            {/* Anna Francis */}
            {/* <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={anna} alt='Anna Francis' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Anna Francis</h2>
                        <h3>Ballet/Jazz 1, Jazz/Hip Hop 1:</h3>
                        <p>Anna is a senior at Cedar Valley High School and is in her first year of teaching at IMDS.
                            Anna has been our main teacher substitute at the studio for 3 years and currently enjoys
                            teaching two combination classes this year.   Dance has been her “whole life” as she has been
                             dancing for 14 years.  Anna is an integral part of our Elite Company dance team and has competed
                              with the team for 4 years. She also currently works as a CNA and loves being a caregiver and helping people.</p>
                    </div>
                </div>
            </div> */}



              {/* ----Izzy Stockton */}
              {/* <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={izzy} alt='Izzy Stockton' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Izzy Stockton</h2>
                        <h3>Jazz 2</h3>
                        <p>Izzy has danced since she was 3 and loves jazz, lyrical and contemporary.  Izzy is a Sophomore at Cedar Valley and dances on our 
                            Elite Company dance team. Izzy has been an assistant at IMDS and is in her first year of teaching her own dance class.  
                            She enjoys music, dogs, Dr. Pepper and hanging out with friends.</p>
                    </div>
                </div>
            </div> */}
            
            {/* ---Zaiah Johnson */}
            {/* <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={zaiah} alt='Zaiah_Johnson' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Zaiah Johnson</h2>
                        <h3>Ballet/Jazz &#38; Jazz/Hip Hop:</h3>
                        <p>Zaiah has danced for 5 years and has been on Ballet Pointe and in the Ballet Company for 1 year.  She enjoys every minute of dancing and was 
                            able to dance the part of “White Cat” in the IMDS production of Sleeping Beauty.  She is a currently in the 10th grade at Utah Virtual Academy.
                              Zaiah enjoys singing, painting, sketching and writing; she is currently working on a book.  She has had the opportunity to teach multiple 
                              age groups during the last 2 summer sessions and is very excited to take on her own class this year.</p>
                    </div>
                </div>
            </div>
            */}

          
            {/* ----Emma Barnes */}
            {/* 
            <div class="container">
                <div class="row">
                <div class="staff-column-33">
                        <img src={emma} alt='Emma_Barnes' width='80%'/>
                    </div>
                    <div class="staff-column-66">
                        <h2>Emma Barnes</h2>
                        <h3>Jazz/Hip Hop 1, Jazz 2, 3 &#38; 4/5 (Eclipse Company):</h3>
                        <p>This is Emma’s second year teaching dance and first year teaching at In Motion Dance Studio.  She has been dancing from the age of 5 and has danced
                             at In Motion since she was 7 where her love for dance has continued to grow.  Emma is trained in Ballet, Jazz, Lyrical &#38; Hip Hop.  When she isn’t 
                             dancing, Emma loves to play the piano, read and write and is involved in two school clubs.</p>
                    </div>
                </div>
            </div>
            */}
         
        </div>
            
    )
}
