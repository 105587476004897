import React from 'react'
import { Helmet } from 'react-helmet';
import '../css/fitness.css';
/* Import the image to be shown on the page */
import schedule from '../images/Preschool.png';

export default function Preschool() {
    return (
        <div className="Preschool-content">
            <Helmet>
                <title>In Motion Dance | PreSchool</title>
            </Helmet>
            <h1> In Motion Dance Studio PreSchool<br/></h1>
            <h2 style={{ color: 'red' }}>PreSchool Start Date -> Tuesday, August 20, 2024<br/></h2>
            <h5><br/>Updated: Friday, June 14, 2024</h5>
            <h1></h1>
            <img src={schedule} alt='PreSchool Schedule for 2024'/>            
        </div>
    )
}