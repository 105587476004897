import React from 'react'
import { Helmet } from 'react-helmet';
import '../css/class-info.css';
import {Link} from 'react-router-dom';

export default function ClassInfo() {
    return (
        <div className="classInfo-content">
            <Helmet>
                <title>In Motion Dance | Class Info</title>
            </Helmet>
            <h1> Class Information </h1>
            <p>Here you'll find any and all information you need for classes, performances, schedules, etc.</p><br/><br/>
            
            <p>Click here for information on <Link to="/performances">performances!</Link> </p>
            <p>Click here for infomation on <Link to="/rates">costs and rates!</Link> </p>
            <p>Click here for information on our studio's <Link to="/policy">policies and dress code!</Link> </p>
            <p>Click here for <Link to="/classbyage">information on our classes</Link>, catagorized by age for your convenience!</p>
            <p>Click here for information on our <Link to="/fitness">fitness program!</Link> </p>
            <p>Click here for a convenient overview of our studio's <Link to="/schedule">weekly schedule!</Link>  </p>
            <p>Click here to see our studio's <Link to="/gallery">gallery!</Link>  </p>

        </div>
    )
}
