import React from 'react'
import { Helmet } from 'react-helmet';
import '../css/fitness.css';
/* Import the image to be shown on the page */
import schedule2 from '../images/Summer-Schedule-2023-TOP-5-16-23.png';
import schedule3 from '../images/Summer-Schedule-2023-BOTTOM-5-16-23.png';
import schedule4 from '../images/Summer-Dance-Camp-2024.png';


export default function Summer() {
    return (
        <div className="fitness-content">
            <Helmet>
                <title>In Motion Dance | Summer</title>
            </Helmet>
            <h1> In Motion Dance Studio<br/> Summer Schedule 2024 </h1>
            <img src={schedule2} alt='Fitness Schedule for 2023 Top'/><br/>
            <img src={schedule3} alt='Fitness Schedule for 2023 Bottom'/><br/><br/>
            <img src={schedule4} alt='Fitness Schedule for 2023 Way Bottom'/>        
        </div>
    )
}