import React from 'react'
import '../css/policy.css';
import { Helmet } from 'react-helmet';

export default function Policy() {
    return (
        <div className="policy-content">
            <Helmet>
                <title>In Motion Dance | Policies &#38; Dress Code</title> 
            </Helmet>
            <h1> Policies and Dress Code </h1>
            
            <div className="policy-general">
                <h2>General</h2>
                    <ul>
                        <li>No food, gum or drink in the studios (with the exception of  water).</li>
                        <li>Attendance is very important to ensure each student’s progress. Students who miss 3 consecutive classes without prior arrangement may be dismissed from upcoming performances.</li>
                        <li>To avoid injury, students should arrive on time for a proper warm-up.  If a student is more than 10 min late, they may be asked to observe.</li>
                        <li>Promotions to higher class levels will be decided by the teacher &#38; owner (by invitation or audition).</li>
                        <li>If a student causes damage to the studio, the student/parent will be held responsible.</li>
                        <p><i><u>Note: </u>the ballet barres are not to be hung on. They are mounted for balance, not holding weight.</i></p>
                        <li>If a student is being disruptive, they may be excused for the remainder of the class. The teacher will fill out a form to document what occurred.</li>
                        <li>Pictures will be taken.  Please let us know if you do not want pictures of your child published.</li>
                    </ul>
            </div>
            
            <div className="policy-dropOff">
                <h2>Pick-Up / Drop-Off</h2>
                <p><strong>Policy:</strong></p>
                    <ul>
                        <li>After class is over, we would appreciate it if your child is left no longer than 5 minutes to be picked
                             up.  If you are running late, please call the studio cell number so that we can make arrangements.  
                             Please instruct your child to wait inside of the studio. We do not have office staff available to 
                             watch your child. If you are late, you assume full responsibility for your child’s welfare.</li>
                    </ul>
                <p><strong>Assumption of Responsibilities and Risks</strong></p>
                    <ul>
                        <li>Please be aware that dance, like any physical activity, comes with the risk of potential injury. 
                            Registration at In Motion Dance Studio includes understanding and accepting the risk and assuming
                             responsibility for the safety of your child. We will take every step possible to provide a safe 
                             and healthy learning environment for you and your dancer.</li>
                    </ul>
            </div> 
            
            <div className="policy-addTransfer">
            <h2>Adding / Transferring Classes</h2>
                <p>If a student wishes, they may transfer to a different class as long as there are openings in the class.
                     After notifying office personnel, and if the class is the same price, no other action is needed. To 
                     add additional classes, a student must notify office personnel, so that the class can be added to 
                     tuition. If adding in the middle of a month, a student will pay a prorated class rate until the new
                      month begins.</p>
            </div> 
            
            <div className="policy-latefee">
            <h2>Late Fees</h2>
                <p> If tuition is not paid in full by the 7th day of each month, a $10 <u>per week</u> late fee will begin
                 to be assessed until tuition is paid in full.</p>
            </div> 
           
            <div className="policy-cancelation">
            <h2>Canceling Classes</h2>
                <p>You <strong>MUST</strong> notify us in writing (email, phone or text) by 2 weeks prior to the 1st of the next month. For 
                    example: cancelling for February requires notification by January 18th, etc.  If we receive notice after
                     that date but before the 1st of the next month, you will be charged a $20 cancellation fee.  Cancelling 
                     between the 1st and the 7th of the month, you will receive half tuition refund.  Cancelling anytime after
                      the 7th, you will not receive a tuition refund for the current month.  Please notify us if you don’t 
                      receive a cancellation confirmation email/text from us.<br/><br/>If cancelling for one month (exceptions are made for illness and injury), you may lose your spot in the class and will need to re-enroll in the studio. This includes having to pay another registration fee.</p>
            </div> 
            
            <div className="policy-makeup">
            <h2>Make-Up Classes</h2>
                <p>If a student misses a class or we are closed for a holiday, students are invited to attend any class 
                    that is their same level during the week. Classes must be “made up” within 30 days of the missed class.
                     They do not carry over. We also allow for anticipatory make-ups, meaning if students know that they 
                     will be out-of-town for a certain week, they can make-up the class before they leave. Make sure to 
                     inform the teacher that the student is doing a make-up class.</p>
            </div> 
            <div className="policy-endOfSeason">
            <h2>End Of Season Information</h2>
                <p>Fall/Spring dance season culminates in our Spring Recital on Saturday, June 15th.  Classes end Friday, June 14th. Tuition will not be charged in July and August, 
                    unless you register for summer session. </p>
            </div> 
            <div className="policy-dressCode">
            <h2>Dress Code</h2>
                <p>Dancers must adhere to the dress code by coming prepared to class. Wear appropriate shoes and proper 
                    dance clothing. Hair must be out of face. Please make sure your child gets the appropriate dance attire.
                     We plan to incorporate some of these items into open house /recital costumes to keep fees down.<br/><br/>
                     <i><u>Note:</u> No boy-cut shorts without tights,  tank tops, leotards without tights, low cut leotards, backless leotards or midriff baring attire.</i></p> 

                <p>    
                    <ul>
                        <li><u>Mini Combo &#38; Creative</u> – Bare feet, footless tights, any form fitting dance attire. Dress ups and tutus are fine.</li>
                    </ul>
                    <ul>
                        <li><p><strong>Combos:</strong></p></li>
                        <ul>    
                            <li><u>BTT+ &#38; BTT Combo – (Ballet, Tap, Tumbling)</u> - Black short sleeve leotard, pink convertible tights, black tap shoes &#38; pink ballet slippers. (skirts &#38; tutus are okay)</li>
                            <li><u>BJT Combo – (Ballet, Jazz, Tumbling)</u> Black short sleeve leotard, pink convertible tights &#38; ballet slippers (skirts &#38; tutus are okay) </li>
                            <li><u>PreSchool Dance</u> - Please wear comfortable clothing and bring ballet shoes.</li>
                        </ul>
                    </ul>
                    <ul>    
                        <li><p><strong>Jazz:</strong></p></li>
                            <ul>
                                <li><u>Jazz /Ballet combo</u> – Black short sleeve leotard, pink footless tights, pink ballet slippers, bare feet for jazz.</li>
                                <li><u>Jazz 2</u> – Form fitting dance clothing with sleeves, no tanks tops, bare feet or Footundees. Hair must be pulled back out of the face.</li>
                                <li><u>Jazz 3</u> – Form fitting dance clothing with sleeves, no tanks tops, bare feet or Footundees. Hair must be pulled back out of the face.</li>
                            </ul>    
                        <li><u>Jazz/Hip Hop combo</u> – short sleeve leotard (any color), black leggings and bare feet.</li>
                        <li><u>Hip Hop &#38; Break</u> – Street clothes, with non-marking tennis shoes. No tank tops.</li>
                        <li><u>Contemporary (Lyrical)</u> – Form fitting dance attire and bare feet.</li>
                        <li><u>Ballet</u> – Black leotard (cap sleeve, short sleeve or 3/4 length sleeves, no spaghetti or tank leotards allowed), pink ballet shoes with leather soles, pink tights, hair secured back in a bun, black or pink skirts (adults can wear any form fitting attire for ballet).</li>
                        <li><u>Tap</u> – Tap shoes and street clothing.</li>
                        <li><u>Tumblers</u> – Form fitting attire, a short sleeved leotard and bottoms or shirts that are long enough to keep stomach covered. </li>
                    </ul>
                </p>    
            </div>           
        </div>
    )
}