import React from 'react'
import { Helmet } from 'react-helmet';
import '../css/schedule.css';

export default function Schedule2() {
    return (
        <div className="schedule-content">
            <Helmet>
                <title>In Motion Dance | Schedule</title>
            </Helmet>
            <h1>Fall/Spring Schedule<br/>(Sept 3, 2024 -June 13, 2025)</h1>
            <h2>Updated: Tuesday, September 24th, 2024</h2>
            <h2><strong>Fitness classes are in</strong> BOLD</h2>
            <div className="schedule-gridWrapper">

                {/* Monday */}
                <div className="schedule-mondayColumn">
                <h2>Monday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                            <th>Room 2</th>
                        </tr>
                        <tr>
                            <td><br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><br/></td>
                            <td></td>
                        </tr>
                        <td></td><td></td>
                        <tr>
                            <td>Ballet/Jazz 1 Combo<br/>(AGES 5-7)<br/>3:00-3:50<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Hip Hop/Jazz 1 Combo<br/>(AGES 5-7)<br/>3:50-4:40<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Intermediate BTT<br/>(Ballet/Tap/Tumbling)<br/>(AGES 5-7)<br/>4:45-5:30<br/></td>
                            <td>(Tumbling)<br/>INT BTT <br/>5:15-5:30<br/>BTT<br/>5:30-5:45</td>
                        </tr>
                        <tr>
                            <td>BTT<br/>(Ballet/Tap/Tumbling)<br/>(AGES 3-5)<br/>5:30-6:15<br/>(Start in Room 2)</td>
                            <td>Tumbling 2<br/>(AGES 8-11)<br/>5:45-6:45</td>
                        </tr>
                        <tr>
                            <td>Ballet 5/6<br/>Pre-Pointe<br/>6:15-7:00<br/></td>
                            <td>Tap 2<br/>(AGES 8-11)<br/>6:45-7:30<br/></td>
                           </tr>                        
                        <tr>
                            <td>Eclipse Dance Co.<br/>(Class 1)<br/>7:00 - 8:00<br/></td>
                            <td>Adult &#38; Teen Tap<br/>7:30 - 8:30<br/></td>
                            </tr>
                        <tr>
                            <td><strong>Dance Fitness<br/>8:00-9:00</strong></td>
                        </tr>
                    </table>
                </div>
                {/* Tuesday */}
                <div className="schedule-tuesdayColumn">
                <h2>Tuesday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                            <th>Room 2</th>
                        </tr>
                        <tr>
                            <td>Preschool<br/>(AGES 3-4)<br/>9:30-11:30<br/></td>
                            <td>BJT (Ballet/Jazz/Tumbling)<br/>(AGES 3-5)<br/>9:45-10:30</td>
                        </tr>
                        <tr>
                            <td>Preschool Cont.</td>
                            <td>BTT (Ballet/Tap/Tumbling)<br/>(AGES 3-5)<br/>10:30-11:15</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Preschool<br/>(AGES 4-5)<br/>12:30-2:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Pointe<br/>4:00-5:00<br/>2 Classes Req</td>
                            <td>Tumbling Tots<br/>(AGES 2-5)<br/>3:30-4:15</td>
                        </tr>
                        
                        <tr>
                            <td>Adult Ballet<br/>5:00-6:00</td>
                            <td>Tumbling 1<br/>(AGES 5-7)<br/>4:15-5:15</td>
                        </tr>
                        <tr>
                            <td>Adult Hip Hop<br/>6:00-6:45</td>
                            <td>Tumbling 3<br/>(Skill Level)<br/>5:15-6:45<br/></td>
                        </tr>
                        <tr>
                            <td>Contemporary/<br/>Lyrical 2/3<br/>(AGES 8+)<br/>6:45-7:45</td>
                        </tr>
                        <tr>
                            <td><strong>Zumba<br/>7:45-8:30</strong></td>
                        </tr>
                        </table>
                </div>
                {/* Wednesday */}
                <div className="schedule-wednesdayColumn">
                <h2>Wednesday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                            <th>Room 2</th>
                        </tr>
                        <tr>
                            <td>Tumbling Tots<br/>(AGES 2-5)<br/>9:30-10:15<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Mini-Combo Dance/Tumbling<br/>(AGES 18mos-3yr)<br/>10:15-10:45<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>BTT<br/>(Ballet/<br/>Tap/Tumbling)<br/>(AGES 3-5)<br/>10:45-11:30<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>PreSchool<br/>(AGES 4-5)<br/>12:30-2:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Elite Dance Company<br/>(Class 1)<br/>3:30-5:00</td>
                            <td>Ballet 1<br/>(AGES 5-7)<br/>4:00-4:45</td>
                        </tr>
                        <tr>
                            <td>Eclipse Dance<br/>Company<br/>(Class 2)<br/>5:00-6:00</td>
                            <td>Ballet 1<br/>(AGES 5-7)<br/>4:45-5:30</td>
                        </tr>
                        <tr>
                            <td>Hip Hop 2<br/>(AGES 8-11)<br/>6:00-7:00</td>
                            <td>Ballet 2<br/>(AGES 8-11)<br/>5:30-6:30</td>
                        </tr>
                        <tr>
                            <td>Pointe<br/>7:00-8:20<br/>2 Classes Req</td>
                            <td>Jazz 2<br/>(AGES 8-11)<br/>6:30-7:30</td>
                        </tr>
                        <tr>
                            <td><strong>YOGA<br/>8:30-9:30</strong></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
                {/* Thursday */}
                <div className="schedule-thursdayColumn">
                <h2>Thursday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                            <th>Room 2</th>
                        </tr>
                        <tr>
                            <td>PreSchool<br/>(AGES 3-4)<br/>9:30-11:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Preschool Cont.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>PreSchool<br/>(AGES 4-5)<br/>12:30-2:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>BTT<br/>(Ballet/Tap/Tumbling)<br/>(AGES 3-5)<br/>4:00-4:45</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Pointe<br/>5:00-6:00<br/>2 Classes Req<br/></td>
                            <td>Tap 3<br/>(Skill Level)<br/>5:00-6:00</td>
                        </tr>
                        <tr>
                            <td>Ballet 5/6<br/>6:00-7:00<br/>(Skill Level)</td>
                            <td>Jazz 3/4<br/>(AGES 12+)<br/>6:00-7:00</td>
                        </tr>
                        <tr>
                            <td>Ballet 3/4<br/>(AGES 8+ & Skill Level)<br/>7:00-8:00</td>
                            <td>Hip Hop 3/4<br/>(AGES 12+)<br/>7:00-8:00</td>
                        </tr>
                        
                    </table>
                </div>
                {/* Friday */}
                <div className="schedule-fridayColumn">
                <h2>Friday</h2>
                    <table>
                        <tr>
                            <td>Extended Combo (BTT+)<br/>(AGES 3-5)<br/>10:30-11:30<br/>(Ballet/Tap/Tumbling)<br/>plus rotating styles</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>PreSchool<br/>(AGES 4-5)<br/>12:30-2:30</td>
                        </tr>
                        <tr>
                            <td>Elite Dance Company<br/>Class 2<br/>3:30-5:00</td>
                        </tr>
                        <tr>
                            <td>Emerge Dance Co<br/>4:00-5:30</td>
                        </tr>
                        
                        

                    </table>
                </div>
                {/* Saturday */}
                <div className="schedule-saturdayColumn">
                <h2>Saturday</h2>
                    <table>
                        <tr>
                            <td>RESERVED for Company Rehersals<br/>8:00-10:00</td>
                        </tr>
                        <tr>
                            <td>Break 1/2<br/>(AGES 5-11)<br/>10:00-11:00</td>
                        </tr>
                        <tr>
                            <td>Break 3<br/>(AGES 12+)<br/>11:00-12:00</td>
                        </tr>
                    </table>
            </div>
            </div>                        
        </div>
    )
}