import React from 'react'
import { Helmet } from 'react-helmet';
import '../css/schedule.css';

export default function Schedule() {
    return (
        <div className="schedule-content">
            <Helmet>
                <title>In Motion Dance | Schedule</title>
            </Helmet>
            <h1>Fall/Spring Schedule<br/>(Sept 5, 2023 -June 14, 2024)</h1>
            <h2>Updated: Thursday, April 11, 2024</h2>
            <h2><strong>Fitness classes are in</strong> BOLD</h2>
            <div className="schedule-gridWrapper">

                {/* Monday */}
                <div className="schedule-mondayColumn">
                <h2>Monday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                            <th>Room 2</th>
                        </tr>
                        
                        <tr>
                            <td>Ballet Tap Tumbling<br/>(AGES 3-5)<br/>9:45-10:30<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Ballet Jazz Tumbling<br/>(AGES 3-5)<br/>10:30-11:15<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Private Rental<br/>11:30-3:00<br/></td>
                            <td></td>
                        </tr>
                            <td></td><td></td>
                        <tr>
                            <td>Ballet/Jazz 1 Combo<br/>(AGES 5-7)<br/>3:00-3:50<br/></td>
                            <td>Tumbling Tots<br/>(AGES 2-5)<br/>3:45-4:30<br/></td>
                        </tr>
                        <tr>
                            <td>Hip Hop/Jazz 1 Combo<br/>(AGES 5-7)<br/><strong>(FULL)</strong><br/>3:55-4:45<br/></td>
                            <td>Tumbling 1<br/>(AGES 5-7)<br/>4:30-5:15<br/></td>
                        </tr>
                        <tr>
                            <td>INT BTT<br/>(AGES 5-7)<br/><strong>2 Spots Remaining</strong><br/>4:45-5:30<br/></td>
                            <td>RESERVED<br/>for INT BTT & BTT<br/>5:15-5:45<br/></td>
                        </tr>
                        <tr>
                            <td>Ballet Tap Tumb<br/>(AGES 3-5)<br/>5:30-6:15<br/>(Start in Room 2)</td>
                            <td>Tumbling 2<br/>(AGES 8-11)<br/>5:45-6:30</td>
                        </tr>
                        <tr>
                            <td>Ballet 5<br/>(Pre Pointe)<br/>6:15-7:00<br/>(2 classes REQ)</td>
                            <td>Tap 2<br/>(AGES 7+ or<br/> Skill Level)<br/>6:30-7:15</td>
                           </tr>                        
                        <tr>
                            <td>Eclipse Dance Co.<br/>7:00 - 8:00<br/>(Tryout REQ)</td>
                            <td>Adult &#38; Teen Tap<br/>7:15-8:15<br/></td>
                        </tr>
                        <tr>
                        <td><strong>Dance Fitness<br/>8:00-9:00</strong></td>
                            <td></td>
                        </tr>
                       
                        
                    </table>
                </div>
                {/* Tuesday */}
                <div className="schedule-tuesdayColumn">
                <h2>Tuesday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                            <th>Room 2</th>
                        </tr>
                        <tr>
                            <td>Ballet Jazz Tumbling<br/>(AGES 3-5)<br/><strong>2 Spots Remaining</strong><br/>9:30-10:15<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Ballet Tap Tumbling<br/>(AGES 3-5)<br/><strong>2 Spots Remaining</strong><br/>10:15-11:00<br/></td>
                            <td></td>
                        </tr>
                            <td></td>
                            <td></td>
                        <tr>
                            <td>Ballet 2<br/>(AGES 7-11)<br/><strong>(FULL)</strong><br/>4:00-5:00<br/></td>
                            <td>Tumbling Tots<br/>(AGES 2-5)<br/>3:45-4:30<br/></td>
                        </tr>
                        <tr>
                            <td>Ballet 7/8 Pointe (Int/Adv)<br/>5:00-6:00<br/>(2 Classes REQ)</td>
                            <td>Tumbling 1<br/>(AGES 5-7)<br/>4:30-5:15<br/></td>
                        </tr>
                        
                        <tr>
                            <td>Adult Ballet<br/>6:00-7:00</td>
                            <td>Tumbling 3<br/>(Skill Level)<br/>5:15-6:45<br/></td>
                        </tr>
                        <tr>
                            <td>Adult Hip Hop<br/>7:00-7:45</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><strong>Zumba<br/>7:45-8:45</strong></td>
                        </tr>
                        
                        </table>
                </div>
                {/* Wednesday */}
                <div className="schedule-wednesdayColumn">
                <h2>Wednesday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                            <th>Room 2</th>
                        </tr>
                        <tr>
                            <td>Tumbling Tots<br/>(AGES 2-5)<br/>9:00-9:45<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Mini-Combo Dance/Tumbling<br/>(AGES 18mos-3yr)<br/>9:45-10:15<br/></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Elite Dance Co.<br/>CLASS 1<br/>(Tryout REQ)<br/>3:00-4:30</td>
                            <td>Ballet 1a<br/>(AGES 5-7)<br/>3:45-4:30<br/></td>
                        </tr>
                        <tr>
                            <td>Ballet 1b<br/><strong>2 Spots Remaining</strong><br/>(AGES 5-7)<br/>4:30-5:15<br/></td>
                            <td>Eclipse Dance Co.(Tryout REQ)<br/>4:00-5:30</td>
                        </tr>
                        <tr>
                            <td>Hip Hop 2<br/>(AGES 8-11)<br/>5:15-6:15<br/></td>
                            <td>Lyrical/<br/>Contemporary 3&4<br/>AGES 8+<br/>5:30-6:30</td>
                        </tr>
                        <tr>
                            <td>Jazz 2<br/>(AGES 8-11)<br/>6:15-7:15</td>
                            <td>Jazz 3<br/>(AGES 12+ or Skill Level)<br/>6:30-7:30</td>
                        </tr>
                        <tr>
                            <td>Ballet 6 Pointe (Beg)<br/>7:15-8:30<br/>(2 classes REQ)</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><strong>Yoga<br/>8:30-9:30</strong></td>
                        </tr>
                    </table>
                </div>
                {/* Thursday */}
                <div className="schedule-thursdayColumn">
                <h2>Thursday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                            <th>Room 2</th>
                        </tr>
                        <tr>
                            <td>Extended Combo (BTT+)<br/>(AGES 3-5)<br/>9:30-10:30<br/>(Ballet/Tap/Tumbling)<br/>plus rotating styles<br/>(Hip Hop, Jazz, Hula, Irish, Modern, African, Break)</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Ballet 5<br/>(Skill Level)<br/>4:00-5:00<br/>(2 classes REQ)</td>
                            <td>Break 1<br/>(AGES 5-7)<br/>4:00-4:45</td>
                        </tr>
                        <tr>
                            <td>Ballet 7<br/>Pointe (Int)<br/>5:00-6:30<br/>(2 classes REQ)</td>
                            <td>Break 2<br/>(AGES 8-11)<br/>4:45-5:30</td>
                        </tr>
                        <tr>
                            <td>Ballet 7 continued</td>
                            <td>Tap 3<br/>(Skill Level)<br/>5:30-6:30</td>
                        </tr>
                        <tr>
                            <td>Ballet 3&4<br/>(AGES 12+ or Skill Level)<br/>6:30-7:30<br/>(Room 2)</td>
                            <td>Lyrical/<br/>Contemporary 1&2<br/>(AGES 5-7)<br/>6:30-7:30<br/>(Room 1)</td>
                        </tr>
                        <tr>
                            <td>Hip Hop 3&4<br/>(AGES 12+ or Skill Level)<br/>7:30-8:30</td>
                            <td></td>
                        </tr>
                        
                    </table>
                </div>
                {/* Friday */}
                <div className="schedule-fridayColumn">
                <h2>Friday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                        </tr>
                        <tr>
                            <td>Preschool Dance<br/>(AGES 3-6)<br/><strong>2 Spots Remaining</strong><br/>9:30-11:30<br/>(dance + school basics)</td>
                        </tr>
                        <tr><br/></tr>
                        <tr>
                            <td>Elite Dance Company<br/>(Skill Level)<br/>Class 2<br/>3:00-4:30</td>
                        </tr>
                        <tr>
                            <td>Emerge Dance Company<br/>(Tryout REQ)<br/>4:00-5:00</td>
                        </tr>
                       
                        
                        

                    </table>
                </div>
                {/* Saturday */}
                <div className="schedule-saturdayColumn">
                <h2>Saturday</h2>
                    <table>
                        <tr>
                            <th>Room 1</th>
                        </tr>
                        <tr>
                            <td>Private Rental<br/>7:00-8:00</td>
                        </tr>
                        <tr>
                            <td>RESERVED for Company Rehersals<br/>9:00-11:00</td>
                        </tr>
                        <tr>
                            <td>Break 3<br/>(AGES 12+ or Skill Level)<br/>11:00-11:45</td>
                        </tr>
                        <tr><br/></tr>
                        <tr>
                            <td>Private Rental<br/>12:00-4:00</td>
                        </tr>
                    </table>
            </div>
            </div>                        
        </div>
    )
}