import React from 'react'
import '../css/performances.css';
import { Helmet } from 'react-helmet';

export default function Schedule() {
    return (
        <div className="performances-content">
            <Helmet>
                <title>In Motion Dance | Performances &#38; Recitals</title>
            </Helmet>
            <h1> Performances &#38; Recitals </h1>
            <p id="performances-topNote">All performances are optional.  Since most dancers do perform, if you choose not to participate 
                you are required to fill out an opt out form.</p><br/>
            <div className="performances-main">
                
                <h2>Performances</h2>
                <p><strong>Winter Showcase:</strong> - (Younger classes) We hold an Open House holiday performance in December (date TBD) at Lake Mountain Middle School in Saratoga Springs (for time, see email).  Those invited to perform at the Festival of Trees (info below) 
                will not perform in this Winter Showcase.  Costume &#38; Participation fee required.</p><br/><br/>

                <p><strong>Festival of Trees</strong> - A fundraising event for Primary Children’s 
                Hospital, held in the South Towne Expo Center in Sandy the first weekend in December 2024 (date TBD).  
                Older classes will be invited to perform (see email for details). Those invited to perform in the Festival of Trees will not perform in the Winter Showcase. 
                 Requires costume fee ($60-$90), participation fee $20 per performer, and entrance fee for spectators.</p><br/><br/>

                 <p><strong>End of Year Dance Recital</strong> - (June): Our End of the year dance recital will be June 15, 2024. Location - Vista Heights Middle School. 
                 To participate in the recital, there are two fees that need to be paid March 15, 2024.
                </p><br/><br/>
            </div>
            <div className="performances-recitals">
                <h2>Recital Fees</h2>
                <p><strong>Costume fee: </strong></p>   
                <ul>
                        <li>$40-$75 fee per child for costumes (per class)</li>
                </ul><br/>
                <p><strong>Participation fee:</strong></p>
                <ul>
                    <li>1 child = $40</li>
                    <li>2 children = $65</li>
                    <li>3+ children = $80 (only 1 fee, even if they're in all 3 recitals)</li>
                </ul><br/>
                <p>If your child is in more than one class you may choose which ones to participate in for recital. All fees 
                    must be paid in full March 15, 2024.  If you are participating, you may pay by check or charge to your 
                    debit/credit card. We need the recital/costume fees paid early since this helps the teachers to begin 
                    ordering costumes. There are no other mandatory fees (like ticket sales, professional photographs, etc)
                     associated with recital.</p> 
            </div>
            <br/><br/><p id="performances-bottomNote"><i><u>Note:</u> We are charged a very high fee to lease a school for the recitals and there 
            are other significant costs associated with putting on a recital at a school. This is the reason for the recital
             fee. By paying this fee, you are free to invite anybody you would like to watch your dancers perform.</i></p>            
        </div>
    )
}